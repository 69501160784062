<template>
  <div class="orderTest-container">
    <!-- NAME[epic=绩效] 提成统计 -->
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.user_id"
          clearable
          style="width: 140px"
          placeholder="请选择员工"
        >
          <el-option
            v-for="(i, idx) in peopleSelect"
            :key="idx"
            :value="i.user_id"
            :label="i.user_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <brand-select
          ref="brandSelect"
          style="width: 160px"
          :popper-class="'select-idx'"
          @brand-select-change="chooseBrand"
        ></brand-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <goods-search
          ref="goodsSearch"
          :is-table="false"
          @add-rows="addRows"
          @input-goods="setkeyword"
          @select-goods-all="selectGoods"
        ></goods-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="hanldeClear">清空</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '业务提成'" @click="hanldeYewu(row)">
            <span class="click-dom">
              {{ row[item.prop] }}
            </span>
          </div>
          <div v-else-if="item.label == '送货提成'" @click="hanldeSonghuo(row)">
            <span class="click-dom">
              {{ row[item.prop] }}
            </span>
          </div>
          <!-- <div v-else-if="item.label == ''"></div> -->
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { peopleList } from '@/api/performance'
  import BrandSelect from '@/baseComponents/brandSelect'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import { downloadFile, postAction } from '@/api/Employee'
  import { dateFormat } from '@/utils/Time'
  export default {
    name: 'CommissionSummary',
    components: { BrandSelect, GoodsSearch },
    data() {
      return {
        time: [],
        loading: false,
        form: {
          pageSize: 10, //
          pageNo: 1, //
          start_time: '', //开始时间
          end_time: '', //结束时间
          user_id: '', //员工id
          goods_id: '',
          goods_name: '',
        },
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        peopleSelect: [],
        list: [],
        checkList: ['员工名称', '业务提成', '送货提成', '提成合计'],
        columns: [
          {
            order: 1,
            label: '员工名称',
            prop: 'user_name',
            width: '',
          },
          {
            order: 2,
            label: '业务提成',
            prop: 'sale_money',
            width: '',
          },
          {
            order: 3,
            label: '送货提成',
            prop: 'back_money',
            width: '',
          },
          {
            order: 4,
            label: '提成合计',
            prop: 'total_money',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {},
    mounted() {
      // 员工下拉
      peopleList({ pageSize: -1 }).then((res) => {
        console.log(res)
        this.peopleSelect = res.data
      })
      this.initTime()
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await postAction(
          '/taskAdmin/extract-info-detail/form-list',
          this.form
        )
        this.list = data
        this.total = totalCount
        this.loading = false
      },
      // 初始时间
      initTime() {
        let month = new Date().getMonth() + 1
        let year = new Date().getFullYear()
        let monthStart = new Date(year + '-' + month + '-1')
        let now = dateFormat(new Date())
        this.time = [dateFormat(monthStart), now]
        this.form.start_time = dateFormat(monthStart)
        this.form.end_time = now
      },
      chooseBrand(v) {
        this.form.brand_id = v
      },
      setkeyword(v) {
        this.form.goods_id = ''
        this.form.goods_name = v
      },
      addRows(v) {},
      selectGoods(v) {
        if (v.goods_id > 0) {
          console.log('v', v)
          this.form.goods_id = v.goods_id
        }
      },
      handleQuery() {
        this.form.pageNo = 1
        this.fetchData()
      },
      hanldeClear() {
        this.form = {
          pageSize: 10, //
          pageNo: 1, //
          start_time: '', //开始时间
          end_time: '', //结束时间
          user_id: '', //员工id
          goods_id: '',
          goods_name: '',
        }
        this.$refs.brandSelect.resetForm()
        this.$refs.goodsSearch.resetForm()
        this.initTime()
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      hanldeYewu(row) {
        this.$router.push({
          name: 'CommissionDetail',
          query: {
            goods_id: this.form.goods_id,
            user_id: row.id,
            user_name: row.user_name,
            start_time: this.form.start_time,
            end_time: this.form.end_time,
            type: 1,
          },
        })
      },
      handleExport() {
        downloadFile(
          '/taskAdmin/extract-info-detail/form-list-export',
          '提成统计.xlsx',
          this.form
        )
      },
      hanldeSonghuo(row) {
        this.$router.push({
          name: 'CommissionDetail',
          query: {
            goods_id: this.form.goods_id,
            goods_name: this.form.goods_name,
            user_id: row.id,
            user_name: row.user_name,
            start_time: this.form.start_time,
            end_time: this.form.end_time,
            type: 2,
          },
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .click-dom {
    text-decoration: underline;
    cursor: pointer;
  }
</style>
